import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from 'docComponents';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="May 2018"
      subnav="release-notes">
      <div id="May2018" className={styles.release}>
        <Paragraph className="uni-margin--one--vert">
          This release features a few additions and <em>a lot</em> of
          improvements. The new action bar component is ready to rock, as are
          icons built with V3 in mind. Our new preview image and accompanying
          text make sharing Uniform a lot prettier, and the esc key has found
          its purpose in life. Read on to learn more.
        </Paragraph>

        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                <Link href="/components/action-bar" isDesignCodeLink>
                  Action bar guidelines
                </Link>{' '}
                for multi-select situations.
              </Added>
              <Added>
                Preview text and image for Slack, Twitter and Facebook
                unfurling.
              </Added>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.7.2"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.7.2"
            />

            <div className={styles.changesList}>
              <Added>
                Lots of props!
                <List>
                  <li>
                    <code>color</code> for the{' '}
                    <Link href="/components/type/text/code">text</Link> and{' '}
                    <Link href="/components/icon/code">icon</Link> components
                  </li>
                  <li>
                    <code>showBrowserTooltip</code> for{' '}
                    <Link href="/components/icon/code">icons</Link>
                  </li>
                  <li>
                    <code>collapsePadding</code> for{' '}
                    <Link href="/components/buttons/button/code">buttons</Link>
                  </li>
                  <li>
                    the super literal <code>contentOverflowVisible</code> for{' '}
                    <Link href="/components/modal/code">modals</Link>
                  </li>
                </List>
              </Added>
              <Added>
                Our new{' '}
                <Link href="/components/action-bar" isDesignCodeLink>
                  action bar
                </Link>{' '}
                component.
              </Added>
              <Added>
                V3 icons to the mix:
                <List>
                  <li>Clip</li>
                  <li>Keyboard Shortcuts</li>
                  <li>Playlist</li>
                  <li>Playlist Add</li>
                  <li>Reorder</li>
                  <li>Restricted</li>
                </List>
              </Added>
              <Improved>The highlight icon.</Improved>
              <Improved>
                <Link href="/components/selects/single-select" isDesignCodeLink>
                  Single select
                </Link>{' '}
                and{' '}
                <Link href="/components/selects/lookup-select" isDesignCodeLink>
                  lookup select
                </Link>{' '}
                animations, PLUS standardized <code>defaultValue</code> and{' '}
                <code>value</code> for both. (Thanks, Amanda!)
              </Improved>
              <Improved>
                <Link href="/components/modal/code">Modal</Link> markup for new
                styling to better handle overflow content.
              </Improved>
              <Improved>
                <Link href="/components/forms/input" isDesignCodeLink>
                  Input
                </Link>{' '}
                sizing for consistency.
              </Improved>
              <Improved>
                Spacing between form elements like labels and helper text.
              </Improved>
              <Improved>
                Animations and styling for{' '}
                <Link href="/components/forms/radio" isDesignCodeLink>
                  radios
                </Link>{' '}
                and{' '}
                <Link href="/components/forms/checkbox" isDesignCodeLink>
                  checks
                </Link>
                .
              </Improved>
              <Fixed>
                The dismiss on{' '}
                <Link href="/components/selects/single-select" isDesignCodeLink>
                  single select
                </Link>{' '}
                and{' '}
                <Link href="/components/selects/lookup-select" isDesignCodeLink>
                  lookup select
                </Link>{' '}
                to display only when text is present.
              </Fixed>
              <Fixed>
                The esc key to actually dismiss a modal (like{' '}
                <Link href="/components/modal" isDesignCodeLink>
                  our docs
                </Link>{' '}
                said it would).
              </Fixed>
              <Fixed>
                Keyboard shortcuts for{' '}
                <Link href="/components/selects/single-select" isDesignCodeLink>
                  single select
                </Link>{' '}
                and{' '}
                <Link href="/components/selects/lookup-select" isDesignCodeLink>
                  lookup select
                </Link>
                :
                <List>
                  <li>Up and down arrows now navigate a focused field.</li>
                  <li>Esc closes whatever’s expanded.</li>
                </List>
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader
              libName="CSS"
              version="1.4.0"
              versionUrl="https://github.com/hudl/uniform-ui-css/releases/tag/v1.4.0"
            />

            <div className={styles.changesList}>
              <Added>
                Utility classes to remove padding from{' '}
                <Link href="/components/buttons/button/design#Style">
                  minimal buttons
                </Link>
                .
              </Added>
              <Added>
                A class to the <Link href="/components/modal/code">modal</Link>{' '}
                to allow for content overflow.
              </Added>
              <Added>
                Styling for the{' '}
                <Link href="/components/action-bar/code">action bar</Link>.
              </Added>
              <Improved>
                Various <Link href="/components/forms/code">form</Link> styling
                like sizing, spacing and animations.
              </Improved>
              <Improved>
                Overflow mask and content padding for the{' '}
                <Link href="/components/modal/code">modal</Link>.
              </Improved>
              <Fixed>
                Weird unclickable space to the right of the arrow on{' '}
                <Link href="/components/selects/single-select/code">
                  single select
                </Link>{' '}
                and{' '}
                <Link href="/components/selects/lookup-select/code">
                  lookup select
                </Link>
                , plus the focus state for each.
              </Fixed>
              <Fixed>
                Flex shrinking for the{' '}
                <Link href="/components/modal/code">modal</Link> footer, as well
                as unnecessary overlay in the bottom mask.
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="React Native" />

            <div className={styles.changesList}>
              <Added>
                Those same V3 icons:
                <List>
                  <li>Clip</li>
                  <li>Keyboard Shortcuts</li>
                  <li>Playlist</li>
                  <li>Playlist Add</li>
                  <li>Reorder</li>
                  <li>Restricted</li>
                </List>
              </Added>
              <Improved>The highlight icon.</Improved>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Sketch Library" />

            <div className={styles.changesList}>
              <Added>
                Text styles to use with the{' '}
                <Link href="https://github.com/zeroheight/library-styles-sync">
                  Library Styles Sync
                </Link>{' '}
                plugin.
              </Added>
              <Added>That new action bar component.</Added>
              <Added>
                Did we mention the V3 icons?
                <List>
                  <li>Clip</li>
                  <li>Keyboard Shortcuts</li>
                  <li>Playlist</li>
                  <li>Playlist Add</li>
                  <li>Reorder</li>
                  <li>Restricted</li>
                </List>
              </Added>
              <Improved>The highlight icon, of course.</Improved>
              <Improved>
                <Link href="/components/forms" isDesignCodeLink>
                  Form
                </Link>{' '}
                components to match what’s implemented in real life.
              </Improved>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
